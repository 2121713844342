<template>
  <div class="px-315 watch">
    <div style="height: 5.625rem"></div>
    <div class="mt-35 font-20">观看记录</div>
    <!-- 搜索/相关搜索 -->
    <div class="py-30">
      <!-- 搜索框 -->
      <div class="flex a-center cursorp mb-20">
        <el-input
          v-model="keyword"
          placeholder="请输入关键词"
          @keyup.enter.native="search()"
        >
          <div
            class="flex a-center search justify-center"
            slot="append"
            @click="search()"
          >
            <div class="mr-10">
              <i class="el-icon-search"></i>
            </div>
            搜索
          </div>
        </el-input>
      </div>
      <!-- 相关搜索 -->
      <div class="flex font-12 flex-wrap">
        <div class="mr-10 text-333333">相关搜索：</div>
        <div
          v-for="(item, index) in Searchhistory"
          :key="index"
          class="mr-10 text-999999 cursorp"
          @click="history(item.name)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="">
      <!-- 今天 -->
      <div class="py-35 border-bottom border-FCFCFC">
        <div class="flex align-center justify-between mb-25">
          <div class="flex align-center" v-if="flag">
            <div class="btn1 mr-10 cursorp" @click="allsele">全选</div>
            <div class="btn2 cursorp" @click="delehistory">删除</div>
          </div>
          <div v-else></div>
          <div v-if="!flag" class="cursorp font-14 text-222222" @click="allnosele">
            编辑
          </div>
          <div v-else class="cursorp font-14 text-222222" @click="allnosele">取消</div>
        </div>
        <div class="font-26 text-222222 mb-35">
          今天
          <!-- <div>
            <div class="font-20 cursorp" v-if="!flag" @click="allnosele">编辑</div>
            <div class="font-20 cursorp" v-else @click="allnosele">取消</div>
          </div> -->
        </div>
        <!-- <div class="flex align-center justify-between" v-if="flag">
          <div></div>
          <div class="flex align-center">
            <div class="mr-10 btn cursorp" @click="allsele">全选</div>
            <div class="btn cursorp" @click="delehistory">删除</div>
          </div>
        </div> -->
        <div class="mt-25">
          <div
            v-for="(item, index) in todays"
            :key="index"
            class="flex align-center justify-between mb-35"
          >
            <div class="flex align-center">
              <div style="width: 15.3125rem; height: 8.5rem">
                <el-image
                  class="rounded10"
                  style="width: 15.3125rem; height: 8.5rem"
                  :src="item.img"
                  fit="cover"
                ></el-image>
              </div>
              <!-- <img
                :src="item.img"
                width="15.3125rem"
                height="8.5rem"
                class="rounded10"
                alt=""
              /> -->
              <div class="ml-35">
                <div
                  class="mb-15 font-20"
                  :class="flag && item.flag ? 'text-E7524C' : ''"
                >
                  {{ item.name }}
                </div>
                <div class="mb-25 font-14 text-666666">
                  {{ item.percent }} 今天{{ item.time }}
                </div>
                <div class="jxbtn cursorp" @click="gofilmdetails(item.film_id)">
                  <!-- <img
                    src="@/assets/img/btnplay.png"
                    alt=""
                    width="1.3125rem"
                    height="1.3125rem"
                  /> -->
                  <el-image
                    style="width: 1.3125rem; height: 1.3125rem"
                    :src="require('@/assets/img/btnplay.png')"
                    fit="cover"
                  ></el-image>
                  <div class="ml-10 font-12 text-white">继续观看</div>
                </div>
              </div>
            </div>
            <div class="sele" v-if="flag">
              <!-- <img
                src="@/assets/img/sele_act.png"
                width="1.6875rem"
                height="1.6875rem"
                class="cursorp"
                alt=""
                v-if="item.flag"
                @click="sele(item, index)"
              /> -->
              <el-image
                class="cursorp"
                style="width: 1.6875rem; height: 1.6875rem"
                :src="require('@/assets/img/sele_act.png')"
                fit="cover"
                v-if="item.flag"
                @click="sele(item, index)"
              ></el-image>
              <el-image
                class="cursorp"
                style="width: 1.6875rem; height: 1.6875rem"
                :src="require('@/assets/img/sele.png')"
                fit="cover"
                v-else
                @click="sele(item, index)"
              ></el-image>
              <!-- <img
                src="@/assets/img/sele.png"
                alt=""
                width="1.6875rem"
                height="1.6875rem"
                class="cursorp"
                v-else
                @click="sele(item, index)"
              /> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 7天内 -->
      <div class="py-35 border-bottom border-FCFCFC">
        <div class="font-26 text-222222 mb-35">7天内</div>
        <div class="">
          <div
            v-for="(item, index) in sevendays"
            :key="index"
            class="flex align-center justify-between mb-35"
          >
            <div class="flex align-center">
              <div style="width: 15.3125rem; height: 8.5rem">
                <el-image
                  class="rounded10"
                  style="width: 15.3125rem; height: 8.5rem"
                  :src="item.img"
                  fit="cover"
                ></el-image>
              </div>
              <!-- <img
                :src="item.img"
                width="15.3125rem"
                height="8.5rem"
                class="rounded10"
                alt=""
              /> -->
              <div class="ml-35">
                <div class="font-20" :class="flag && item.flag ? 'text-E7524C' : ''">
                  {{ item.name }}
                </div>
                <div class="mb-25 font-14 text-666666">
                  {{ item.abstract }}
                </div>
                <div class="jxbtn cursorp" @click="gofilmdetails(item.film_id)">
                  <!-- <img
                    src="@/assets/img/btnplay.png"
                    alt=""
                    width="1.3125rem"
                    height="1.3125rem"
                  /> -->
                  <el-image
                    style="width: 1.3125rem; height: 1.3125rem"
                    :src="require('@/assets/img/btnplay.png')"
                    fit="cover"
                  ></el-image>
                  <div class="ml-10 font-12 text-white">继续观看</div>
                </div>
              </div>
            </div>
            <div class="sele" v-if="flag">
              <!-- <img
                src="@/assets/img/sele_act.png"
                width="1.6875rem"
                height="1.6875rem"
                alt=""
                class="cursorp"
                v-if="item.flag"
                @click="sele(item, index)"
              /> -->
              <el-image
                class="cursorp"
                style="width: 1.6875rem; height: 1.6875rem"
                :src="require('@/assets/img/sele_act.png')"
                fit="cover"
                v-if="item.flag"
                @click="sele(item, index)"
              ></el-image>
              <el-image
                class="cursorp"
                style="width: 1.6875rem; height: 1.6875rem"
                :src="require('@/assets/img/sele.png')"
                fit="cover"
                v-else
                @click="sele(item, index)"
              ></el-image>
              <!-- <img
                src="@/assets/img/sele.png"
                alt=""
                width="1.6875rem"
                height="1.6875rem"
                class="cursorp"
                v-else
                @click="sele(item, index)"
              /> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 更早 -->
      <div class="py-35 border-bottom border-FCFCFC">
        <div class="font-26 text-222222 mb-35">更早</div>
        <div class="">
          <div
            v-for="(item, index) in earlier"
            :key="index"
            class="flex align-center justify-between mb-35"
          >
            <div class="flex align-center">
              <div style="width: 15.3125rem; height: 8.5rem">
                <el-image
                  class="rounded10"
                  style="width: 15.3125rem; height: 8.5rem"
                  :src="item.img"
                  fit="cover"
                ></el-image>
              </div>
              <!-- <img
                :src="item.img"
                width="15.3125rem"
                height="8.5rem"
                class="rounded10"
                alt=""
              /> -->
              <div class="ml-35">
                <div class="font-20" :class="flag && item.flag ? 'text-E7524C' : ''">
                  {{ item.name }}
                </div>
                <div class="mb-25 font-14 text-666666">
                  {{ item.abstract }}
                </div>
                <div class="jxbtn cursorp" @click="gofilmdetails(item.film_id)">
                  <!-- <img
                    src="@/assets/img/btnplay.png"
                    alt=""
                    width="1.3125rem"
                    height="1.3125rem"
                  /> -->
                  <el-image
                    style="width: 1.3125rem; height: 1.3125rem"
                    :src="require('@/assets/img/btnplay.png')"
                    fit="cover"
                  ></el-image>
                  <div class="ml-10 font-12 text-white">继续观看</div>
                </div>
              </div>
            </div>
            <div class="sele" v-if="flag">
              <!-- <img
                src="@/assets/img/sele_act.png"
                width="1.6875rem"
                height="1.6875rem"
                alt=""
                v-if="item.flag"
                class="cursorp"
                @click="sele(item, index)"
              /> -->
              <el-image
                v-if="item.flag"
                class="cursorp"
                @click="sele(item, index)"
                style="width: 1.6875rem; height: 1.6875rem"
                :src="require('@/assets/img/sele_act.png')"
                fit="cover"
              ></el-image>
              <el-image
                v-else
                class="cursorp"
                @click="sele(item, index)"
                style="width: 1.6875rem; height: 1.6875rem"
                :src="require('@/assets/img/sele.png')"
                fit="cover"
              ></el-image>
              <!-- <img
                src="@/assets/img/sele.png"
                alt=""
                width="1.6875rem"
                height="1.6875rem"
                v-else
                class="cursorp"
                @click="sele(item, index)"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../utils/req";
export default {
  data() {
    return {
      flag: false,
      keyword: "",
      Searchhistory: [],
      page: 1,
      todays: [],
      sevendays: [],
      earlier: [],
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let keyword = this.$route.query.keyword;
        if (keyword) {
          this.keyword = keyword;
        } else {
          this.keyword = "";
        }
        this.getdata();
        this.page = 1;
        this.getdata2();
      },
      deep: true,
    },
  },
  mounted() {
    let keyword = this.$route.query.keyword;
    if (keyword) {
      this.keyword = keyword;
    }
    this.getdata();
    this.page = 1;
    this.getdata2();
  },
  methods: {
    // 跳转详情
    gofilmdetails(id) {
      this.$router.push({
        path: "/filmdetails",
        query: { type: "filmdetails", moveid: id },
      });
    },
    // 删除观赏记录
    delehistory() {
      let that = this;
      let arr = [];
      if (that.todays && that.todays.length) {
        that.todays.forEach((item) => {
          if (item.flag) {
            arr.push(item.id);
          }
        });
      }
      if (that.sevendays && that.sevendays.length) {
        that.sevendays.forEach((item) => {
          if (item.flag) {
            arr.push(item.id);
          }
        });
      }
      if (that.earlier && that.earlier.length) {
        that.earlier.forEach((item) => {
          if (item.flag) {
            arr.push(item.id);
          }
        });
      }
      let str = arr.join(",");
      console.log(str);
      this.$confirm("是否删除观赏记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          req
            .post("index/delViewing", {
              id: str,
            })
            .then((res) => {
              if (res.code != 200) {
                that.$message.error(res.msg);
                return;
              }
              that.$message.success(res.msg);
              that.page = 1;
              that.todays = [];
              that.sevendays = [];
              that.earlier = [];
              that.getdata2();
            })
            .catch((err) => {
              console.log(err);
              that.$message.error(err.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 选择
    sele(item, i) {
      if (item.type == 1) {
        this.todays[i].flag = !this.todays[i].flag;
      } else if (item.type == 2) {
        this.sevendays[i].flag = !this.sevendays[i].flag;
      } else if (item.type == 3) {
        this.earlier[i].flag = !this.earlier[i].flag;
      }
    },
    // 编辑/取消按钮
    allnosele() {
      this.flag = !this.flag;
      if (this.todays && this.todays.length) {
        this.todays.forEach((item) => {
          item.flag = false;
        });
      }
      if (this.sevendays && this.sevendays.length) {
        this.sevendays.forEach((item) => {
          item.flag = false;
        });
      }
      if (this.earlier && this.earlier.length) {
        this.earlier.forEach((item) => {
          item.flag = false;
        });
      }
    },
    // 全选
    allsele() {
      if (this.todays && this.todays.length) {
        this.todays.forEach((item) => {
          item.flag = true;
        });
      }
      if (this.sevendays && this.sevendays.length) {
        this.sevendays.forEach((item) => {
          item.flag = true;
        });
      }
      if (this.earlier && this.earlier.length) {
        this.earlier.forEach((item) => {
          item.flag = true;
        });
      }
    },
    // 点击记录搜索
    history(name) {
      this.$router.push({
        path: "/search",
        query: {type: "search", keyword: name },
      });
    },
    search() {
      this.$router.push({
        path: "/search",
        query: {type: "search", keyword: this.keyword },
      });
    },
    // 获取观赏记录
    getdata2() {
      let that = this;
      req
        .post("index/viewingLog", {
          page: this.page,
        })
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          res.data.data.forEach((item) => {
            item.flag = false;
          });
          that.addList(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 将数据分类
    addList(res) {
      let [todays, sevendays, earlier] = [[], [], []];
      console.log(res);
      // 获取插入的方向
      let getDirection = (item) => {
        console.log("item", item);
        if (item.type == 1) {
          return "todays";
        }
        if (item.type == 2) {
          return "sevendays";
        }
        if (item.type == 3) {
          return "earlier";
        }
      };

      // 将数据源分为左右两个列表，容错差值请自行根据项目中的数据情况调节
      res.forEach((item) => {
        if (getDirection(item) == "todays") {
          //console.log(`差值：${differ},方向：left，序号${index}`)
          todays.push(item);
        } else if (getDirection(item) == "sevendays") {
          //console.log(`差值：${differ},方向：right，序号${index}`)
          sevendays.push(item);
        } else {
          earlier.push(item);
        }
        // i++;
      });
      console.log("todays", todays);
      console.log("sevendays", sevendays);
      console.log("earlier", earlier);
      // 将左右列表的数据插入，第一页时则覆盖
      if (this.page == 1) {
        this.todays = todays;
        this.sevendays = sevendays;
        this.earlier = earlier;
      } else {
        if (todays && todays.length) {
          this.todays = [...this.todays, ...todays];
        }
        if (sevendays && sevendays.length) {
          this.sevendays = [...this.sevendays, ...sevendays];
        }
        if (earlier && earlier.length) {
          this.earlier = [...this.earlier, ...earlier];
        }
      }
    },
    // 获取搜索历史记录
    getdata() {
      let that = this;
      req
        .post("index/searchPage", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.Searchhistory = res.data;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss">
.watch {
  .el-input-group {
    width: auto;
  }
  .el-input__inner {
    -webkit-appearance: none;
    background-color: #e6e6e6;
    // border-radius: 6.25rem;
    border-top-left-radius: 6.25rem;
    border-bottom-left-radius: 6.25rem;
    border: 0;
    box-sizing: border-box;
    color: #222222;
    display: inline-block;
    font-size: inherit;
    height: 2.1875rem;
    line-height: 2.1875rem;
    outline: 0;
    padding: 0 0.9375rem;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 20.5625rem;
  }
  .search {
    width: 6.5625rem;
    height: 2.1875rem;
    line-height: 2.1875rem;
    background-color: #c9372e;
    color: #fff;
    border-radius: 6.25rem;
    margin-left: -1.125rem;
    cursor: pointer;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    background-color: #c9372e;
    color: #fff;
    vertical-align: middle;
    display: table-cell;
    border: 0;
    border-radius: 6.25rem;
    padding: 0;
    white-space: nowrap;
  }
  .btn {
    width: 4.9375rem;
    height: 1.875rem;
    background: #ffffff;
    border: 0.0625rem solid #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .jxbtn {
    width: 6.9375rem;
    height: 2.1875rem;
    background: linear-gradient(-90deg, #ffa98b, #e7514b);
    box-shadow: 0 0.3125rem 0.5625rem 0 rgba(255, 82, 76, 0.24);
    border-radius: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn1 {
    width: 4.9375rem;
    height: 1.875rem;
    background: #ffffff;
    border: 0.0625rem solid #ef6247;
    color: #e7524c;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.875rem;
  }
  .btn2 {
    width: 4.9375rem;
    height: 1.875rem;
    background: #ffffff;
    border: 0.0625rem solid #e5e5e5;
    color: #666666;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.875rem;
  }
}
</style>
